import { render, staticRenderFns } from "./SimsView.vue?vue&type=template&id=299d8429"
import script from "./SimsView.vue?vue&type=script&setup=true&lang=js"
export * from "./SimsView.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SimsView.vue?vue&type=style&index=0&id=299d8429&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports