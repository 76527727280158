import { useStore } from "@/store";

export const useNotifications = () => {
  const store = useStore();

  const showNotification = ({ message }) => {
    console.log({ message });
    store.commit("notification", message);
  };

  return {
    showNotification,
  };
};
